import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation, usePaarams } from "react-router-dom";
import { setTitle } from "../../plugins/Utils";
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Tabs,
  Tab,
  Typography,
  createStyles,
  Theme,
  withStyles,
  TextField,
} from "@material-ui/core";
import useDBUserStatus from "../../plugins/useFSUser";
import TopBar from "../Common/components/TopBar";
import fsController from "../../plugins/FireStoreController";
import { User, UserReaction } from "../../plugins/Schemas";
import { Skeleton } from "@material-ui/lab";
import { styles } from "../../theme";
import { ReactionCardList } from "../Common/components/ReactionList";
import { useSnackbar } from "notistack";
import { useDebounce } from "react-use";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#F2F2F2",
    minHeight: "100vh",
  },
  main: {
    backgroundColor: styles.color.topbar,
  },
  avatar: {
    width: "8rem",
    height: "8rem",
  },
  nickname: { fontWeight: "bold" },
  description: { marginTop: theme.spacing(1) },
  submitButton: {
    color: "white",
    fontWeight: "bold",
    fontSize: "1rem",
    textTransform: "none",
  },
  formBox: {
    maxWidth: "700px",
  },
  formTitle: {
    fontWeight: "bold",
    color: "rgba(0,0,0,0.82)",
  },
  userTextInfo: {
    maxWidth: `calc(700px - 8rem - ${theme.spacing(2) * 4}px)`,
  },
  Tabs: {
    maxWidth: `calc(548px + ${theme.spacing(4)}px)`,
  },
}));

const SettingsPage: React.FC = () => {
  const classes = useStyles();
  const [dbUser, dbLoading, error] = useDBUserStatus();

  const init = async (): Promise<void> => {
    setTitle(`プロフィール編集`);
  };

  useEffect(() => {
    init();
  }, [dbLoading]);

  console.log(dbUser);

  return (
    <div className={classes.root}>
      <TopBar showBorder />
      <Box bgcolor={styles.color.topbar} pb={3}>
        <Container maxWidth={"lg"}>
          {dbUser && <ProfileForm user={dbUser} />}
        </Container>
      </Box>
    </div>
  );
};

const ProfileForm: React.FC<{ user: User }> = ({ user }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [inputValue, setInputValue] = useState({
    userId: user.user_id == user.id ? "" : user.user_id,
    nickName: user.nickname,
    description: user.description,
    _changedName: "",
  });
  const [error, setError] = useState<{
    [key: string]: { isError: boolean; text: string };
  }>({
    userId: { isError: false, text: null },
    nickName: { isError: false, text: null },
    description: { isError: false, text: null },
  });

  const _setError = (name: string, isError: boolean, text: string): void => {
    setError({
      ...error,
      [name]: {
        isError: isError,
        text: text,
      },
    });
  };

  const hasError = (): boolean => {
    for (const key in error) {
      if (error[key].isError) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (inputValue.userId == "") {
      _setError("userId", true, "必須項目です");
    }
  }, []);

  const validateInput = async (name: string, value: string): Promise<void> => {
    console.log("validate", name, value);
    let errorText = null;
    switch (name) {
      case "userId": {
        const isValidPattern = /^[a-zA-Z0-9_]+$/.test(value);
        if (!isValidPattern) {
          errorText = "半角英数字とアンダースコア（_）のみ使用可能です";
          break;
        }
        if (!(4 <= value.length && value.length <= 15)) {
          errorText = "4-15文字で入力してください";
          break;
        }
        const isUserExists = await fsController.getUserByAppUserId(value);
        if (value != user.user_id && isUserExists) {
          errorText = `${value} は他のユーザが使用しています`;
          break;
        }
        break;
      }
      case "nickName":
        if (value.length == 0) {
          errorText = "最低1文字以上入力してください";
        }
        if (value.length > 50) {
          errorText = "50文字以内で入力してください";
        }
        break;
      case "description":
        break;
      default:
        break;
    }
    _setError(name, Boolean(errorText), errorText);
  };
  useDebounce(
    () => {
      console.log(inputValue._changedName, inputValue[inputValue._changedName]);
      validateInput(
        inputValue._changedName,
        inputValue[inputValue._changedName]
      );
    },
    200,
    [inputValue]
  );

  const onChangeInput = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    e.persist();
    const { name, value } = e.target;
    const _value = value.replace("\n", "");

    setInputValue((prevState) => ({
      ...prevState,
      [name]: _value,
      _changedName: name,
    }));
  };

  const onClickSubmit = async (): Promise<void> => {
    const { userId, nickName, description } = inputValue;
    console.log("updateUserProfile", userId, nickName, description);
    await fsController.updateUserProfile(
      user.id,
      userId,
      nickName,
      description
    );
    console.log("done");
    enqueueSnackbar("プロフィールを更新しました", {
      variant: "success",
      preventDuplicate: true,
    });
    history.push(`/user/${userId}`);
  };

  console.log("render", hasError());

  return user ? (
    <Box m={"0 auto"} pt={4} className={classes.formBox}>
      <Box>
        <Typography variant="h5" className={classes.formTitle}>
          プロフィール編集
        </Typography>
      </Box>
      <Box pt={2} display="flex" flexWrap="wrap">
        <Box m={2}>
          <Avatar
            alt={user.nickname}
            src={user.photo_url}
            className={classes.avatar}
          />
        </Box>
        <Box m={2} className={classes.userTextInfo}>
          <TextField
            label="ユーザーID"
            variant="outlined"
            value={inputValue.userId}
            onChange={onChangeInput}
            name="userId"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            error={error.userId.isError}
            helperText={error.userId.text}
          />
          <TextField
            label="名前"
            variant="outlined"
            value={inputValue.nickName}
            onChange={onChangeInput}
            name="nickName"
            style={{ marginTop: 30 }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            error={error.nickName.isError}
            helperText={error.nickName.text}
          />
          <TextField
            label="自己紹介"
            variant="outlined"
            placeholder={`はじめまして！私の名前は${user.nickname}です。これからどうぞよろしくね。`}
            value={inputValue.description}
            name="description"
            onChange={onChangeInput}
            multiline
            rows={4}
            fullWidth
            style={{ marginTop: 30 }}
            InputLabelProps={{
              shrink: true,
            }}
            error={error.description.isError}
            helperText={error.description.text}
          />
          <Box display="flex" justifyContent="center" mt={3}>
            <Button
              variant="contained"
              color="primary"
              className={classes.submitButton}
              onClick={onClickSubmit}
              disabled={hasError()}
            >
              保存する
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    // Dummy
    <Box display="flex" flexWrap="wrap" maxWidth={"750px"} m={"0 auto"}>
      <Box m={2}>
        <Skeleton
          variant="circle"
          className={classes.avatar}
          animation="wave"
        />
      </Box>
      <Box m={2} className={classes.userTextInfo} style={{ marginTop: 0 }}>
        <Skeleton
          variant="text"
          height={"5rem"}
          width={"26rem"}
          animation="wave"
        />
        <Skeleton variant="text" width={"26rem"} animation="wave" />
        <Skeleton variant="text" width={"26rem"} animation="wave" />
        <Skeleton variant="text" width={"26rem"} animation="wave" />
      </Box>
    </Box>
  );
};

export default SettingsPage;
