import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { last, setTitle } from "../../plugins/Utils";
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Tabs,
  Tab,
  Typography,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core";
import useDBUserStatus from "../../plugins/useFSUser";
import TopBar from "../Common/components/TopBar";
import fsController from "../../plugins/FireStoreController";
import { Follow, FsDate, User, UserReaction } from "../../plugins/Schemas";
import { Skeleton } from "@material-ui/lab";
import { styles } from "../../theme";
import { ReactionCardList } from "../Common/components/ReactionList";
import InfiniteScroll from "react-infinite-scroller";

const userTextInfoMaxWidth = "26rem";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#F2F2F2",
    minHeight: "100vh",
  },
  main: {
    backgroundColor: styles.color.topbar,
  },
  avatar: {
    width: "8rem",
    height: "8rem",
  },
  nickname: { fontWeight: "bold" },
  description: { marginTop: theme.spacing(1) },
  followButton: {
    color: "white",
    fontWeight: "bold",
    fontSize: "0.9rem",
    textTransform: "none",
  },
  userTextInfo: {
    width: userTextInfoMaxWidth,
  },
  pageList: {
    padding: `${theme.spacing(2)}px`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: "0 auto",
    width: `calc(548px + ${theme.spacing(4)}px)`, // Twitterの埋め込みに合わせる。
    maxWidth: "100%",
  },
  Tabs: {
    maxWidth: `calc(548px + ${theme.spacing(4)}px)`,
  },
}));

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 50,
      width: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.87)",
    },
  },
})((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      minWidth: "80px",
      padding: theme.spacing(2),
      paddingTop: 0,
      paddingBottom: 0,
      "&:focus": {
        opacity: 1,
      },
    },
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

const UserDetail: React.FC = () => {
  const classes = useStyles();
  const [dbUser, dbLoading, error] = useDBUserStatus();
  const [user, setUser] = useState<User>();
  const [reactions, setReactions] = useState<UserReaction[]>(null);
  const [isFollow, setIsFollow] = useState<boolean>(null);
  const [tabIndex, setTabIndex] = React.useState(0);
  const { userId } = useParams();
  const history = useHistory();

  const loadPer = 20;
  const [loadState, setLoadState] = useState<{
    startTime: FsDate;
    loading: boolean;
    hasMore: boolean;
  }>({ loading: false, startTime: null, hasMore: true });

  const isMyPage = (): boolean => {
    return user && dbUser && user.id == dbUser.id;
  };

  const handleTabIndexChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ): void => {
    setTabIndex(newValue);
  };

  const loadMoreUserReaction = async (): Promise<void> => {
    // 追加読み込み
    const currentUser = user;
    if (currentUser && currentUser.id && !loadState.loading) {
      await setLoadState({ ...loadState, loading: true });
      const userReactions = await fsController.getUserReactionsByUserId(
        currentUser.id,
        loadPer,
        loadState.startTime
      );
      if (userReactions && userReactions.length > 0) {
        const _rs = reactions || [];
        await setReactions([..._rs, ...userReactions]);
        setLoadState({
          loading: false,
          hasMore: true,
          startTime: last<UserReaction>(userReactions).created_at,
        });
      } else {
        setLoadState({
          loading: false,
          hasMore: false,
          startTime: loadState.startTime,
        });
      }
    }
  };

  const init = async (): Promise<void> => {
    const user = await fsController.getUserByAppUserId(userId);
    console.log("getUser", userId, user);
    setTitle(`${user.nickname}のいいね`);
    await setUser(user);
  };

  const updateFollow = async (): Promise<void> => {
    if (dbUser && user) {
      const currentFollow = await fsController.getFollow(dbUser.id, user.id);
      setIsFollow(Boolean(currentFollow));
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    updateFollow();
  }, [dbUser, user]);

  useEffect(() => {
    loadMoreUserReaction();
  }, [user]);

  const onClickButton = async (): Promise<void> => {
    if (isMyPage()) {
      history.push("/settings/profile");
      return;
    }
    if (isFollow) {
      // unfollow
      await fsController.deleteFollowUser(dbUser.id, user.id);
      await updateFollow();
    } else {
      await fsController.postFollowUser(dbUser.id, user.id);
      await updateFollow();
    }
  };

  console.log(dbUser);

  return (
    <div className={classes.root}>
      <TopBar />
      <Box bgcolor={styles.color.topbar}>
        <Container>
          <UserInfo
            user={user}
            isMyPage={isMyPage()}
            onClickButton={onClickButton}
            isFollow={isFollow}
          />
        </Container>
        <Box className={classes.Tabs} m={"0 auto"} mt={0}>
          <StyledTabs value={tabIndex} onChange={handleTabIndexChange}>
            <StyledTab label="すべてのいいね" />
          </StyledTabs>
        </Box>
      </Box>
      <Box className={classes.pageList}>
        <InfiniteScroll
          loadMore={loadMoreUserReaction}
          hasMore={loadState.hasMore}
        >
          <ReactionCardList
            reactions={reactions}
            blankText={`${
              user ? user.nickname : "ユーザー"
            } は"いいね"を探しています...`}
          />
        </InfiniteScroll>
      </Box>
    </div>
  );
};

const UserInfo: React.FC<{
  user: User;
  isMyPage: boolean;
  onClickButton: () => void;
  isFollow: boolean;
}> = ({ user, isMyPage, onClickButton, isFollow }) => {
  const classes = useStyles();
  return user && isFollow != null ? (
    <Box display="flex" flexWrap="wrap" maxWidth={"770px"} m={"0 auto"}>
      <Box m={2}>
        <Avatar
          alt={user.nickname}
          src={user.photo_url}
          className={classes.avatar}
        />
      </Box>
      <Box m={2} className={classes.userTextInfo}>
        <Typography variant="h3" className={classes.nickname}>
          {user.nickname}
        </Typography>
        <Typography className={classes.description}>
          {user.description ||
            `はじめまして！私の名前は${user.nickname}です。これからどうぞよろしくね。`}
        </Typography>
      </Box>
      <Box m={2}>
        <Button
          variant="contained"
          color="primary"
          className={classes.followButton}
          onClick={onClickButton}
        >
          {isMyPage ? "編集" : isFollow ? "フォロー中" : "フォロー"}
        </Button>
      </Box>
    </Box>
  ) : (
    // Dummy
    <Box display="flex" flexWrap="wrap" maxWidth={"770px"} m={"0 auto"}>
      <Box m={2}>
        <Skeleton
          variant="circle"
          className={classes.avatar}
          animation="wave"
        />
      </Box>
      <Box
        m={2}
        mt={0}
        width={userTextInfoMaxWidth}
        className={classes.userTextInfo}
      >
        <Skeleton variant="text" height="5rem" animation="wave" />
        <Skeleton variant="text" animation="wave" />
        <Skeleton variant="text" animation="wave" />
      </Box>
      <Box m={2} mt={0}>
        <Skeleton width="5rem" height="4rem" animation="wave" />
      </Box>
    </Box>
  );
};

export default UserDetail;
