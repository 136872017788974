import React, { useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import { Menu, MenuItem } from "@material-ui/core";
import { logout } from "../../../plugins/Auth";
import useFSUser from "../../../plugins/useFSUser";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1,
  },
  linkItem: {
    "& a": { textDecoration: "none" },
    "& a:visited": { color: "inherit" },
  },
}));

const IconMenu: React.FC = () => {
  const classes = useStyles();
  const [user, ,] = useFSUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const onClickIcon = (event): void => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseIcon = (): void => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Button onClick={onClickIcon}>
        {user ? (
          <Avatar alt={user.nickname} src={user.photo_url} />
        ) : (
          <Avatar />
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={onCloseIcon}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem className={classes.linkItem}>
          <Link to="/home">ホーム</Link>
        </MenuItem>
        {user && (
          <MenuItem className={classes.linkItem}>
            <Link to={`/user/${user.user_id}`}>プロフィール</Link>
          </MenuItem>
        )}
        <MenuItem
          onClick={(): void => {
            logout();
            history.push("/login");
          }}
        >
          ログアウト
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default IconMenu;
