import React, { ElementType } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "1rem 2rem",
  },
}));

const H1: React.FC = ({ children }) => (
  <Typography
    variant="h4"
    style={{
      marginTop: "1rem",
    }}
  >
    {children}
  </Typography>
);

const H2: React.FC = ({ children }) => (
  <Typography
    variant="h5"
    style={{
      marginTop: "1rem",
      fontWeight: 600,
    }}
  >
    {children}
  </Typography>
);

const Body: React.FC = ({ children }) => (
  <Typography
    variant="body1"
    style={{
      marginTop: "1rem",
    }}
  >
    {children}
  </Typography>
);

const PrivacyPage: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <H1>アプリケーションプライバシーポリシー</H1>
      <H2>第1条（定義）</H2>
      <Body>
        本プライバシーポリシーは、川本
        峻頌（以下、「当開発者」）が開発したGoogleChromeの拡張機能およびWebアプリケーション（以下、「本アプリケーション」とします。）の利用において、利用者情報を取り扱う際に、当開発者が遵守する方針を示したものです。
      </Body>
      <H2>第2条（基本方針）</H2>
      <Body>
        当開発者は、個人情報の重要性を認識し、個人情報を保護することが社会的責務であると考え、個人情報に関する法令を遵守し、本アプリケーションで取扱う個人情報の取得、利用、管理を適正に行います。
      </Body>
      <H2>第3条（利用者情報の取得と利用目的）</H2>
      <Body>
        当開発者は、本アプリケーションの提供にあたり、下記に記載する利用者情報をアプリケーション経由で自動的に取得あるいは利用者自身の入力により取得し、利用目的の達成に必要な範囲で取り扱います。
      </Body>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>利用者情報</TableCell>
              <TableCell>利用目的</TableCell>
              <TableCell>取得方法</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                ・OS名、バージョン
                <br />
                ・ユーザーエージェント
                <br />
                ・本アプリケーション内での行動履歴
                <br />
                ・ランダムに生成されたUUID
              </TableCell>
              <TableCell>本アプリケーションの提供および改善のため</TableCell>
              <TableCell>アプリケーションによる自動取得</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ユーザ登録情報</TableCell>
              <TableCell>本アプリケーションへのユーザ登録のため</TableCell>
              <TableCell>
                アプリケーションによる自動取得もしくは利用者による入力操作
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                その他本アプリケーション上で利用者が入力した情報{" "}
              </TableCell>
              <TableCell>本サービスの提供のため</TableCell>
              <TableCell>利用者による入力操作</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <H2>第4条（同意）</H2>
      <Body>
        本アプリケーションは、本プライバシーポリシーをご確認いただき、内容をご理解したうえでご利用ください。本プライバシーポリシーは本アプリケーションWebページに掲示されており、この掲示をもって公表したものとします。利用者は本アプリケーションをインストールする前に、本プライバシーポリシーをご確認ください。
      </Body>
      <H2>第5条（利用者情報の端末外部へのデータ送信および第三者提供等）</H2>
      <Body>
        当開発者は第3条に基づいて取得した利用者情報を、当開発者の管理するサーバに転送します。また、本アプリケーションの品質向上を目的として、下記の事業者が提供する情報収集モジュールを使用し、当該事業者を通じて本アプリケーション上の行動履歴情報および端末情報を匿名で自動取得します。
        <br />
        事業者：Google Inc. 情報収集モジュール名：Google Analytics, Firebase
        <br />
        Analytics プライバシーポリシー：
        <a href="https://policies.google.com/privacy">
          https://policies.google.com/privacy
        </a>
      </Body>
      <H2>第6条（利用者情報の自動取得の停止）</H2>
      <Body>
        利用者が本アプリケーションを端末より削除（アンインストール）した場合、本アプリケーションによる自動取得は直ちに停止されます。
      </Body>
      <H2>第7条（サービスの終了と情報の取り扱い）</H2>
      <Body>
        利用者が、本アプリケーションを端末から削除（アンインストール）した場合、本アプリケーション内の利用者情報は全て端末より直ちに削除されます。利用者が本サービスの利用を終了した場合、当開発者は取得した利用者情報を個人を特定できないよう加工した統計データとして保有します。
      </Body>
      <H2>第8条（情報の開示、提供）</H2>
      <Body>
        当開発者は、利用者の同意を得ることなく、本アプリケーションにて利用者より取得、保存した情報を、本プライバシーポリシーに明示している第三者以外に開示または提供することはありません。
        ただし、以下の場合は除きます。
        <br />
        1.令に基づく場合。
        <br />
        2.人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合。
        <br />
        3.国の機関もしくは地方公共団体またはその委託を受けたものが、法令の定めにより遂行することに協力する必要がある場合であって、本人の同意を得ることによりその遂行に支障を及ぼすおそれがある場合。
        <br />
        4.その他、社会通念上、当社が必要と判断した場合であって、本人の同意を得ることが困難である場合。
      </Body>
      <H2>第9条（問い合わせ窓口）</H2>
      <Body>
        本アプリケーションにおける利用者情報の取扱いに関するお問い合わせ、ご相談は、下記のメールアドレスにてお問い合わせください。
        <br />
        メールアドレス:shunyo.kawamoto@gmail.com
      </Body>
      <H2>第10条（変更） </H2>
      <Body>
        1.
        当開発者は、法令の変更等に伴い、本プライバシーポリシーを変更することがあります。
        <br />
        2.
        当開発者は、本アプリケーションのバージョンアップに伴って、利用者情報の取得項目の変更や追加、利用目的の変更、第三者提供等について変更がある場合には、ダウンロード前に通知し、重要なものについてはインストール前もしくはインストール時にあらためて同意を取得いたします。
      </Body>
      <div style={{ marginTop: "1rem", textAlign: "right" }}>
        制定日: 2021年3月21日
      </div>
    </div>
  );
};

export default PrivacyPage;
