import { firestore } from "firebase";
import firebase from "./firebase";
import {
  Follow,
  FsDate,
  User,
  UserReaction,
  ViewedTimeline as UserTimelineViews,
} from "./Schemas";
import { chunk } from "./Utils";

const FSCollections = {
  users: "users",
  user_reactions: "user_reactions",
  follows: "follows",
  user_timeline_views: "user_timeline_views",
};

class FireStoreController {
  private _db: firebase.firestore.Firestore;

  constructor() {
    this._db = firebase.firestore();
  }

  // NOTE: --- Basic Functions ---

  docToData = <T extends {}>(doc: firestore.DocumentData): T => {
    return { id: doc.id, ...doc.data() };
  };

  refToDataList = async <T extends {}>(
    ref: firebase.firestore.CollectionReference | firebase.firestore.Query
  ): Promise<T[]> => {
    return (await ref.get()).docs.map((doc) => {
      return this.docToData<T>(doc);
    });
  };

  getDocsDataAll = async <T extends {}>(
    collectionPath: string
  ): Promise<T[]> => {
    return this.refToDataList<T>(this._db.collection(collectionPath));
  };

  getDocDataById = async <T extends {}>(
    collectionPath: string,
    docId: string
  ): Promise<T> => {
    const _ref = await this._db.collection(collectionPath).doc(docId).get();
    return _ref.exists ? (this.docToData(_ref) as T) : null;
  };

  isDocExists = async (
    collectionPath: string,
    docId: string
  ): Promise<boolean> => {
    const _ref = await this._db.collection(collectionPath).doc(docId).get();
    return _ref.exists;
  };

  // NOTE: User

  isAppUserIdExists = async (userId: string): Promise<boolean> => {
    // return await this.isDocExists(FSCollections.users, userId);
    return !(
      await this._db
        .collection(FSCollections.users)
        .where("userId", "==", userId)
        .get()
    ).empty;
  };

  postUser = async (user: firebase.User): Promise<User> => {
    const userRef = this._db.collection("users").doc(user.uid);
    const dbUser: User = {
      id: user.uid,
      user_id: user.uid,
      email: user.email,
      photo_url: user.photoURL,
      nickname: user.displayName,
      description: "",
      created_at: firestore.Timestamp.now(),
      updated_at: firestore.Timestamp.now(),
    };
    await userRef.set(dbUser);
    console.log("postUser:", dbUser);
    return dbUser;
  };

  updateUserProfile = async (
    docId: string,
    userId: string,
    nickname: string,
    description: string
  ): Promise<void> => {
    const userRef = this._db.collection(FSCollections.users).doc(docId);
    await userRef.update({
      user_id: userId,
      nickname: nickname,
      description: description,
      updated_at: firestore.Timestamp.now(),
    });
  };

  postUserIfNeed = async (user: firebase.User): Promise<User> => {
    // ユーザが登録済みでなければ登録
    const dbUser = await this.getUserById(user.uid);
    if (dbUser) {
      console.log("user exists", dbUser);
      return dbUser;
    } else {
      return await this.postUser(user);
    }
  };

  getUserById = async (docId: string): Promise<User> => {
    // ユーザをIDから取得
    return this.getDocDataById<User>("users", docId);
  };

  getUserByAppUserId = async (userId: string): Promise<User> => {
    // ユーザをアプリ上のIDから取得
    const users = await this.refToDataList<User>(
      this._db.collection(FSCollections.users).where("user_id", "==", userId)
    );
    console.log("getUser", users);
    if (users.length == 0) {
      return null;
    } else {
      return users[0];
    }
  };

  // NOTE: User Reactions

  getUserReactionsByUserId = async (
    userId: string,
    num?: number,
    createdAtAfter?: FsDate
  ): Promise<UserReaction[]> => {
    let q = this._db
      .collection(FSCollections.user_reactions)
      .where("user_id", "==", userId)
      .orderBy("created_at", "desc");
    if (createdAtAfter) {
      q = q.startAfter(createdAtAfter);
    }
    if (num) {
      q = q.limit(num);
    }
    return await this.refToDataList<UserReaction>(q);
  };

  getLatestUserReactionByUserId = async (
    userId: string
  ): Promise<UserReaction> => {
    return (
      await this.refToDataList<UserReaction>(
        this._db
          .collection(FSCollections.user_reactions)
          .where("user_id", "==", userId)
          .orderBy("created_at", "desc")
          .limit(1)
      )
    )[0];
  };

  updateUserReactionPage = async (docId, page): Promise<void> => {
    await this._db
      .collection(FSCollections.user_reactions)
      .doc(docId)
      .update({ page: page });
  };

  // NOTE: Follow User

  getFollows = async (
    fromUserID?: string,
    toUserID?: string
  ): Promise<Follow[]> => {
    let query:
      | firebase.firestore.CollectionReference
      | firebase.firestore.Query = this._db.collection(FSCollections.follows);
    if (fromUserID) {
      query = query.where("from_user_id", "==", fromUserID);
    }
    if (toUserID) {
      query = query.where("to_user_id", "==", toUserID);
    }
    const follows = await this.refToDataList<Follow>(query);
    return follows;
  };

  getFollow = async (fromUserID, toUserID): Promise<Follow> => {
    const follows = await this.getFollows(fromUserID, toUserID);
    if (follows.length > 0) {
      return follows[0];
    } else {
      return null;
    }
  };

  postFollowUser = async (fromUserID, toUserID): Promise<void> => {
    const follows = await this.getFollows(fromUserID, toUserID);
    if (follows.length == 0) {
      const doc = this._db.collection(FSCollections.follows).doc();
      const follow: Follow = {
        id: doc.id,
        from_user_id: fromUserID,
        to_user_id: toUserID,
        created_at: firestore.Timestamp.now(),
        updated_at: firestore.Timestamp.now(),
      };
      await doc.set(follow);
      console.log("post follow", follow);
    } else {
      console.log("Error: follows already exisit", follows);
    }
  };

  deleteFollowUser = async (fromUserID, toUserID): Promise<void> => {
    const follows = await this.getFollows(fromUserID, toUserID);
    if (follows.length > 0) {
      await this._db
        .collection(FSCollections.follows)
        .doc(follows[0].id)
        .delete();
    } else {
      console.log("Error: no follows");
    }
  };

  // NOTE: Viewed Timeline
  getUserTimelineViews = async (
    fromUserID?: string,
    toUserID?: string
  ): Promise<UserTimelineViews[]> => {
    let query:
      | firebase.firestore.CollectionReference
      | firebase.firestore.Query = this._db.collection(
      FSCollections.user_timeline_views
    );
    if (fromUserID) {
      query = query.where("from_user_id", "==", fromUserID);
    }
    if (toUserID) {
      query = query.where("to_user_id", "==", toUserID);
    }
    const data = await this.refToDataList<UserTimelineViews>(query);
    return data;
  };

  postUpdateUserTimelineView = async (
    fromUserID: string,
    toUserID: string
  ): Promise<void> => {
    // なければ作成, 作成済みの場合 last_viewed_at のみ更新
    const views = await this.getUserTimelineViews(fromUserID, toUserID);
    const collection = this._db.collection(FSCollections.user_timeline_views);
    const doc =
      views.length > 0 ? collection.doc(views[0].id) : collection.doc();
    const view: UserTimelineViews = {
      id: doc.id,
      from_user_id: fromUserID,
      to_user_id: toUserID,
      last_viewed_at: firestore.Timestamp.now(),
    };
    await doc.set(view);
  };
}

const fsController = new FireStoreController();
export default fsController;
