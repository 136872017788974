import React, { useState, useEffect, ReactElement } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DateUtil, getUrlMeta, range } from "../../../plugins/Utils";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import fsController from "../../../plugins/FireStoreController";
import { PageDetail, UserReaction } from "../../../plugins/Schemas";
import { Skeleton } from "@material-ui/lab";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import heartBlankImg from "../../../assets/img/heart-blank.png";
import InfiniteScroll from "react-infinite-scroller";
import { topBarHeight } from "./TopBar";

import blankListImg1 from "../../../assets/img/undraw/undraw_Freelancer_re_irh4.svg";
import blankListImg2 from "../../../assets/img/undraw/undraw_join_of2w.svg";
import blankListImg3 from "../../../assets/img/undraw/undraw_Popular_re_mlfe.svg";
import blankListImg4 from "../../../assets/img/undraw/undraw_Raining_re_4b55.svg";
import blankListImg5 from "../../../assets/img/undraw/undraw_road_to_knowledge_m8s0.svg";
import blankListImg6 from "../../../assets/img/undraw/undraw_Surveillance_re_8tkl.svg";
import theme from "../../../theme";
const blankListImgList = [
  blankListImg1,
  blankListImg2,
  blankListImg3,
  blankListImg4,
  blankListImg5,
  blankListImg6,
];
const getRandomBlankImg = (): string =>
  blankListImgList[Math.floor(Math.random() * blankListImgList.length)];

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    display: "flex",
    height: theme.spacing(14),
    marginBottom: theme.spacing(2),
    borderRadius: "15px", // = Twitter Embed
    border: "1px solid rgb(204, 214, 221)", // = Twitter Embed
    width: "548px", // = Twitter Embed
    maxWidth: "100%",
  },
  cardOutline: {
    marginBottom: theme.spacing(2),
    width: "548px", // = Twitter Embed
    borderRadius: "15px", // = Twitter Embed
    border: "1px solid rgb(204, 214, 221)", // = Twitter Embed
    maxWidth: "100%",
  },
  userButton: {},
  cardActionArea: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  cardSection: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "16px",
  },
  cardSectionPoint: {
    marginTop: "3px",
    height: "4px",
    width: "30px",
    backgroundColor: "rgba(0, 0, 0, 0.87)",
  },
  cardContent: {
    flex: 1,
    overflow: "hidden",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardTitle: {
    fontWeight: 600,
    fontSize: "1.1rem",
  },
  cardDescription: {
    display: "-webkit-box",
    WebkitLineClamp: 3,
    overflow: "hidden",
    "-webkit-box-orient": "vertical",
  },
  cardMedia: {
    height: "100%",
    width: theme.spacing(14),
    minWidth: theme.spacing(14),
  },
  cardCaption: {
    color: "gray",
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  blankContent: {
    marginTop: theme.spacing(5),
    textAlign: "center",
  },
  blankContentImg: {
    maxWidth: "400px",
    width: "100%",
  },
  blankContentCaption: {
    marginTop: theme.spacing(2),
  },
}));

export const ReactionCardList: React.FC<{
  reactions: UserReaction[];
  blankText: string;
}> = ({ reactions, blankText }) => {
  const classes = useStyles();
  if (!reactions) {
    return <DummyReactionCardList />;
  } else if (reactions.length == 0) {
    return (
      <div className={classes.blankContent}>
        <img src={getRandomBlankImg()} className={classes.blankContentImg} />
        <Typography
          variant="subtitle2"
          align="center"
          className={classes.blankContentCaption}
        >
          {blankText}
        </Typography>
      </div>
    );
  } else {
    let preReaction = null;
    const elements = reactions.map((r, i) => {
      const dateStr = DateUtil.toStringMonth(r.created_at.toDate());
      let sectionEl = null;
      if (
        !preReaction ||
        DateUtil.toStringMonth(preReaction.created_at.toDate()) != dateStr
      ) {
        sectionEl = (
          <div className={classes.cardSection}>
            {dateStr}
            <div className={classes.cardSectionPoint}></div>
          </div>
        );
      }
      preReaction = r;

      return (
        <div key={`${i} ${r.url}`}>
          {sectionEl}
          <ReactionCard reaction={r} />
        </div>
      );
    });
    return <div>{elements}</div>;
  }
};

const extractYoutubeId = (url: string): string => {
  const regex = /.*youtube\.com\/.*v=/g;
  if (url && url.match(regex)) {
    return url.replace(regex, "").match(/([^&]*)/g)[0];
  } else {
    return null;
  }
};

const extractTweetId = (url: string): string => {
  const regex = /.*twitter\.com\/.*status\//g;
  if (url && url.match(regex)) {
    return url.replace(regex, "").match(/(\d+)/g)[0];
  } else {
    return null;
  }
};

export const ReactionCard: React.FC<{ reaction: UserReaction }> = ({
  reaction,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState<PageDetail>(reaction.page);
  const tweetId = extractTweetId(reaction.url);
  const youtubeId = extractYoutubeId(reaction.url);
  const [el, setEl] = useState(<div />);

  useEffect(() => {
    if (tweetId) {
      setEl(
        <div style={{ marginBottom: theme.spacing(2) }}>
          <TwitterTweetEmbed
            tweetId={tweetId}
            options={{
              width: "100%",
              align: "center",
            }}
            placeholder={
              <Skeleton
                height="500px"
                width="100%"
                variant="rect"
                animation="wave"
                className={classes.cardOutline}
                style={{ marginBottom: 0 }}
              />
            }
          />
        </div>
      );
    } else if (reaction.page && youtubeId) {
      setEl(
        <Card className={classes.cardOutline}>
          <LiteYouTubeEmbed id={youtubeId} title={reaction.page.title} />
        </Card>
      );
    }
  }, [page]);

  useEffect(() => {
    const f = async (): Promise<void> => {
      if (!reaction.page) {
        const page = await getUrlMeta(reaction.url);
        setPage(page);
        console.log(page);
        // もし取得できたら、FireStoreに更新かける
        fsController.updateUserReactionPage(reaction.id, page);
      }
    };
    f();
  }, []);

  const openUrl = (): void => {
    const win = window.open(reaction.url, "_blank");
    win.focus();
  };

  return tweetId || youtubeId ? (
    el
  ) : page ? (
    <Card className={classes.cardRoot} id={reaction.id}>
      <CardActionArea className={classes.cardActionArea} onClick={openUrl}>
        <CardMedia
          image={page.image || heartBlankImg}
          className={classes.cardMedia}
        />
        <CardContent className={classes.cardContent}>
          <Typography
            className={`${classes.ellipsis} ${classes.cardTitle}`}
            variant="h6"
          >
            {page.title || reaction.url}
          </Typography>
          <Typography
            className={classes.cardDescription}
            variant="body2"
            component="p"
          >
            {page.description}
          </Typography>
          {/* <Box display="flex">
            <Box flex={1} />
            <div>
              <Typography className={classes.cardCaption} variant="caption">
                {DateUtil.toStringDate(reaction.created_at.toDate())}
              </Typography>
            </div>
          </Box> */}
        </CardContent>
      </CardActionArea>
    </Card>
  ) : (
    <DummyReactionCard />
  );
};

export const DummyReactionCardList: React.FC = React.memo(() => {
  return (
    <>
      {range(0, 7).map((i) => (
        <DummyReactionCard key={i} />
      ))}
    </>
  );
});

export const DummyReactionCard: React.FC = React.memo(() => {
  const classes = useStyles();
  return (
    <Card className={classes.cardRoot} style={{ minWidth: "548px" }}>
      <Skeleton variant="rect" className={classes.cardMedia} animation="wave" />
      <CardContent className={classes.cardContent}>
        <Typography variant="h5">
          <Skeleton variant="text" animation="wave" />
        </Typography>
        <Skeleton variant="text" animation="wave" />
        <Skeleton variant="text" animation="wave" width="80%" />
      </CardContent>
    </Card>
  );
});
