import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import firebase, { analytics } from "../../plugins/firebase";
import querystring from "querystring";
import { setTitle, sleep } from "../../plugins/Utils";
import heartImg from "../../assets/img/heart.svg";
import { Button } from "@material-ui/core";
import { googleLogin } from "../../plugins/Auth";
import fsController from "../../plugins/FireStoreController";
import { useMountedState } from "react-use";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  main: {
    height: `100vh`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    backgroundImage: `url(${heartImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "120vh",
    backgroundPosition: "170% 50%",
    [theme.breakpoints.down("xs")]: {
      backgroundPosition: "0",
      backgroundSize: "80vh",
    },
  },
  leftItem: {
    zIndex: 10,
    position: "relative",
    padding: "1rem",
  },
  title: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "4rem",
      lineHeight: "4rem",
    },
    fontSize: "8rem",
    lineHeight: "8rem",
    fontWeight: "bold",
  },
  subtitle: {
    marginTop: "1rem",
    fontSize: "1.3rem",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  rightItem: {
    width: "30vw",
  },
  heartImg: {
    position: "absolute",
    right: "-1000px",
    top: "-5vh",
    height: "110vh",
  },
  loginButton: {
    marginTop: "1rem",
    color: "white",
    fontWeight: "bold",
    fontSize: "1rem",
    textTransform: "none",
  },
}));

const LoginSignupPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const isMounted = useMountedState();

  const updateSignUpState = async (): Promise<void> => {
    setLoading(true);
    const res = await firebase.auth().getRedirectResult();
    console.log("getRedirectResult:", res);
    if (isMounted()) {
      setIsSignUp(Boolean(res.user));
      setLoading(false);
    }
    if (res.user) {
      await fsController.postUserIfNeed(res.user);
      await sleep(1000);
    }
  };

  const getNext = (): string => {
    const q = querystring.parse(location.search.replace("?", ""));
    const next = q["next"];
    return next as string;
  };

  const init = async (): Promise<void> => {
    console.log(location);
    await updateSignUpState();
    if (isSignUp) {
      analytics.logEvent("login", {
        method: "google.com",
      });
      const next = getNext();
      if (next) {
        history.push(`/${next}`);
      } else {
        history.push("/home");
      }
    }
  };

  useEffect(() => {
    setTitle("ログイン");
    init();
  }, [isSignUp]);

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.content}>
          <div className={classes.leftItem}>
            <div className={`${classes.title}`}>
              <span>
                Share
                <br />
                Your
                <br />
                Favorites.
              </span>
            </div>
            <div className={classes.subtitle}>あなたの"いいね"が知りたい。</div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.loginButton}
              onClick={googleLogin}
              disabled={loading}
            >
              Google ログイン
            </Button>
          </div>
          <div className={classes.rightItem}></div>
        </div>
      </div>
    </div>
  );
};

export default LoginSignupPage;
