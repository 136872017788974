import { createMuiTheme } from "@material-ui/core/styles";
import { Shadows } from "@material-ui/core/styles/shadows";
import * as colors from "@material-ui/core/colors";

const theme = createMuiTheme({
  typography: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Hiragino Sans", "Noto Sans CJK JP", "Original Yu Gothic", "Yu Gothic", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Sans Emoji',
  },
  shadows: Array(25).fill("none!important") as Shadows,
  palette: {
    primary: {
      main: "#5FA4FB",
    },
    text: {},
    // type: "dark",
  },
});

export const styles = {
  color: {
    primary: "#D85A4C",
    background: "#F2F2F2",
    topbar: "#FFFFFF",
  },
};

export default theme;
