import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useAuthState } from "react-firebase-hooks/auth";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconMenu from "./IconMenu";
import { Box, Button, Container } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { styles } from "../../../theme";

export const topBarHeight = "64px";

const useStyles = makeStyles((theme) => ({
  topBar: {
    height: topBarHeight,
    backgroundColor: styles.color.topbar,
  },
  title: {
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.87)",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "1.7rem",
  },
  toolBar: {
    padding: 0,
  },
}));

const TopBar: React.FC<{ showBorder?: boolean }> = ({ showBorder }) => {
  const classes = useStyles();
  const extStyle = showBorder
    ? { borderBottom: "1px solid rgba(92,147,187,0.17)" }
    : null;
  const history = useHistory();

  return (
    <AppBar position="static" style={extStyle} className={classes.topBar}>
      <Container maxWidth="lg">
        <Toolbar className={classes.toolBar}>
          <div
            className={classes.title}
            onClick={(): void => {
              history.push("/home");
            }}
          >
            <div>Favin</div>
          </div>
          <div style={{ flex: 1 }} />
          <IconMenu />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default TopBar;
