import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { last, setTitle } from "../../plugins/Utils";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import useDBUserStatus from "../../plugins/useFSUser";
import TopBar, { topBarHeight } from "../Common/components/TopBar";
import fsController from "../../plugins/FireStoreController";
import { Follow, FsDate, User, UserReaction } from "../../plugins/Schemas";
import { Skeleton } from "@material-ui/lab";
import { ReactionCardList } from "../Common/components/ReactionList";
import theme from "../../theme";
import InfiniteScroll from "react-infinite-scroller";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#F2F2F2",
    minHeight: "100vh",
  },
  main: {
    justifyContent: "center",
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  userName: {},
  isSelected: { fontWeight: "bold" },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  pageList: {
    maxWidth: "100%",
  },
  userButton: {
    marginRight: theme.spacing(2),
  },
  gridItem: {
    paddingTop: theme.spacing(2),
  },
}));

interface LoadState {
  startTime: FsDate;
  loading: boolean;
  hasMore: boolean;
}

const TimelinePage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [dbUser, dbLoading, error] = useDBUserStatus();
  const [users, setUsers] = useState<User[]>([]);
  const [userUpdates, setUserUpdates] = useState<boolean[]>([]);
  const [userIndex, setUserIndex] = useState(0);
  const [reactions, setReactions] = useState<UserReaction[]>(null);
  const loadPer = 20;
  const [loadState, setLoadState] = useState<LoadState>({
    loading: false,
    startTime: null,
    hasMore: true,
  });

  const init = async (): Promise<void> => {
    if (dbUser) {
      const follows = await fsController.getFollows(dbUser.id);
      const _users = await Promise.all(
        follows.map((f) => fsController.getUserById(f.to_user_id))
      );
      setUsers([dbUser, ..._users]);

      // 更新検知
      const lastViews = await fsController.getUserTimelineViews(dbUser.id);
      const lastViewDict = {};
      for (const view of lastViews) {
        lastViewDict[view.to_user_id] = view.last_viewed_at;
      }
      const updateReactions = await Promise.all(
        _users.map((v) => fsController.getLatestUserReactionByUserId(v.id))
      );
      const updatedUsers = updateReactions.map(
        (r) =>
          !lastViewDict[r.user_id] || r.created_at > lastViewDict[r.user_id]
      );
      // 最初のユーザ（自分）は更新なし
      setUserUpdates([false, ...updatedUsers]);
    }
  };

  useEffect(() => {
    setTitle("タイムライン");
    init();
  }, [dbLoading]);

  const loadMoreUserReaction = async (
    _loadState?: LoadState
  ): Promise<void> => {
    const ls = _loadState ? _loadState : loadState;
    // 追加読み込み
    const currentUser = users[userIndex];
    console.log("loadMoreUserReaction:", currentUser.email, ls);
    if (currentUser && currentUser.id && !ls.loading) {
      await setLoadState({ ...ls, loading: true });
      const userReactions = await fsController.getUserReactionsByUserId(
        currentUser.id,
        loadPer,
        ls.startTime
      );
      if (userReactions && userReactions.length > 0) {
        let preReactions = [];
        if (!_loadState) {
          preReactions = reactions || [];
        }
        console.log("loadMore", userReactions);
        await setReactions([...preReactions, ...userReactions]);
        await setLoadState({
          loading: false,
          hasMore: true,
          startTime: last<UserReaction>(userReactions).created_at,
        });
      } else {
        console.log("loadMore reach End");
        await setLoadState({
          loading: false,
          hasMore: false,
          startTime: null,
        });
      }
    }
  };

  useEffect(() => {
    const f = async (): Promise<void> => {
      // ユーザが選択された時
      const _loadState = {
        loading: false,
        hasMore: true,
        startTime: null,
      };
      await setReactions(null);
      await setLoadState(_loadState);
      const currentUser = users[userIndex];
      console.log("user: ", currentUser, reactions);
      if (currentUser) {
        await loadMoreUserReaction(_loadState);
        fsController.postUpdateUserTimelineView(dbUser.id, currentUser.id);
        const _updates = { ...userUpdates };
        _updates[userIndex] = false;
        setUserUpdates(_updates);
      }
    };
    f();
  }, [users, userIndex]);

  return (
    <div className={classes.root}>
      <TopBar />
      <Container maxWidth={"lg"} className={classes.main}>
        <Box>
          <Grid container>
            <Grid
              xs={12}
              sm={2}
              item
              className={classes.gridItem}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {users.length > 0 ? (
                users.map((u, i) => (
                  <UserItem
                    key={u.id}
                    avatarUrl={u.photo_url}
                    username={u.nickname}
                    isUpdate={userUpdates[i]}
                    isSelected={userIndex == i}
                    onClick={(): void => {
                      setUserIndex(i);
                    }}
                  />
                ))
              ) : (
                <DummyUserItem />
              )}
            </Grid>
            <Grid item xs={12} sm={5} className={`${classes.pageList}`}>
              <div
                style={{
                  height: `calc(100vh - ${topBarHeight})`,
                  overflow: "auto",
                  paddingTop: theme.spacing(2),
                  paddingRight: theme.spacing(2),
                }}
              >
                {users[userIndex] && (
                  <InfiniteScroll
                    loadMore={(): void => {
                      loadMoreUserReaction();
                    }}
                    useWindow={false}
                    hasMore={loadState.hasMore}
                  >
                    <ReactionCardList
                      reactions={reactions}
                      blankText={`${users[userIndex].nickname} は"いいね"を探しています...`}
                    />
                  </InfiniteScroll>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={3} className={classes.pageList}>
              <div>
                {users[userIndex] && (
                  <UserInfo user={users[userIndex]} isMe={userIndex === 0} />
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

const useUserInfoStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    border: "1px solid rgb(204, 214, 221)",
    borderRadius: 0,
  },
  avatar: {
    width: "6rem",
    height: "6rem",
    margin: "0 auto",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  nickname: {
    fontWeight: "bold",
  },
}));

const UserInfo: React.FC<{ user: User; isMe: boolean }> = ({ user, isMe }) => {
  const classes = useUserInfoStyles();
  return (
    <Card className={classes.card}>
      <Container>
        <Avatar
          alt={user.nickname}
          src={user.photo_url}
          className={classes.avatar}
        />
        <Typography className={classes.nickname} variant="h6" align="center">
          {user.nickname}
        </Typography>
        <Typography variant="body1" align="center">
          {user.description}
        </Typography>
      </Container>
    </Card>
  );
};

const DummyUserItem: React.FC = React.memo(() => {
  const classes = useStyles();
  return (
    <Grid
      container
      wrap="nowrap"
      justify="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item>
        <Skeleton variant="circle" className={classes.avatar} />
      </Grid>
      <Grid item zeroMinWidth xs>
        <Skeleton variant="text" width="80%" />
      </Grid>
    </Grid>
  );
});

const UserItem: React.FC<{
  avatarUrl: string;
  username: string;
  isUpdate: boolean;
  isSelected: boolean;
  onClick: () => void;
}> = ({ avatarUrl, username, isUpdate, isSelected, onClick }) => {
  const classes = useStyles();
  return (
    <Button onClick={onClick} className={classes.userButton} disableRipple>
      <Grid
        container
        wrap="nowrap"
        justify="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Badge
            color={"secondary"}
            overlap="circle"
            badgeContent=" "
            variant="dot"
            invisible={!isUpdate}
          >
            <Avatar alt={username} src={avatarUrl} className={classes.avatar} />
          </Badge>
        </Grid>
        <Grid item zeroMinWidth xs>
          <Typography
            noWrap
            variant={"button"}
            className={`${classes.userName} ${
              isSelected && classes.isSelected
            }`}
          >
            {username}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};

export default TimelinePage;
