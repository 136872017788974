import { render } from "react-dom";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  RouteComponentProps,
} from "react-router-dom";
import SettingsPage from "./pages/Settings/SettingsPage";
import LoginSignupPage from "./pages/Login/LoginSignupPage";
import TimelinePage from "./pages/Home/TimelinePage";
import * as React from "react";
import { SnackbarProvider } from "notistack";
import firebase from "./plugins/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./assets/css/destyle.css";
import "./App.css";
import "./plugins/Viewport";
import LoadingPage from "./pages/Common/LoadingPage";
// import useDBUserStatus from "./plugins/useDBUserStatus";
import { useSnackbar } from "notistack";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import UserDetail from "./pages/User/UserDetail";
import PrivacyPage from "./pages/Terms/PrivacyPage";

const rootEl = document.getElementById("root");

declare global {
  interface Window {
    twttr: any;
  }
}

window.twttr = window.twttr || {};

const RedirectRoute: React.FC<RouteComponentProps & { pathname: string }> = ({
  pathname,
}) => {
  return <Redirect to={pathname} />;
};

// const RoleRoute: React.FC<RouteComponentProps & { validRole: UserRole }> = ({
//   component: Component,
//   validRole: validRole,
//   ...rest
// }) => {
//   const [user, loading] = useDBUserStatus();
//   const { enqueueSnackbar } = useSnackbar();
//   if (loading) {
//     return <LoadingPage description={"ユーザ情報を取得中..."} />;
//   }
//   console.log(`RoleRoute(${validRole})`, user);
//   if (user && user.role == validRole) {
//     return (
//       <Route
//         {...rest}
//         render={(props): typeof RouteComponentProps => <Component {...props} />}
//       />
//     );
//   } else {
//     enqueueSnackbar(
//       `${rest.path} は ${user && user.email} には許可されていないURLです.`,
//       {
//         variant: "error",
//       }
//     );
//     return <RedirectRoute {...rest} pathname="/home" />;
//   }
// };

const PrivateRoute: React.FC<RouteComponentProps> = ({
  component: Component,
  ...rest
}) => {
  const [user, loading] = useAuthState(firebase.auth());
  console.log(user ? "login success" : "login failed");
  if (loading) {
    return <LoadingPage description={"ユーザ情報を取得中..."} />;
  }
  if (user) {
    return (
      <Route
        {...rest}
        render={(props): typeof RouteComponentProps => <Component {...props} />}
      />
    );
  } else {
    rest.path = `/login?next=${rest.location.pathname.slice(1)}`;
    return <RedirectRoute {...rest} pathname={rest.path} />;
  }
};

render(
  <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
    <BrowserRouter>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/">
            <Redirect to={{ pathname: "/home" }} />
          </Route>
          <Route exact path="/login" component={LoginSignupPage} />
          <PrivateRoute exact path="/home" component={TimelinePage} />
          <PrivateRoute exact path="/user/:userId" component={UserDetail} />
          <PrivateRoute
            exact
            path="/settings/profile"
            component={SettingsPage}
          />
          <Route exact path="/privacy" component={PrivacyPage} />
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  </SnackbarProvider>,
  rootEl
);
